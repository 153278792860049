import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO
        title="Contactez-nous"
      />
      <div className="mx-auto max-w-screen-xl">
  

      <div className=" pt-10">
      <div className="relative bg-white">
  <div className="absolute inset-0">
    <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50"></div>
  </div>
  <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
    <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
      <div className="max-w-lg mx-auto">
        <h1>
          Contactez nous
        </h1>
        <p className="mt-3 text-lg leading-6 text-gray-500">
          Que ce soit pour une demande de devis ou pour des renseignements sur nos offres n'hésitez pas à nous contacter par téléphone ou en utilisant le formulaire de contact.
        </p>
        <dl className="mt-8 text-base leading-6 text-gray-500">
          <div>
            <dt className="sr-only">Adresse</dt>
            <dd>
              <p>Deltavisit</p>
              <p>3 Rue Paul Rocache</p>
              <p>31100 Toulouse</p>
            </dd>
          </div>
          <div className="mt-6">
            <dt className="sr-only">Téléphone</dt>
            <dd className="flex">
              <svg className="flex-shrink-0 h-6 w-6 text-gray-400" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24">
                <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
              </svg>
              <span className="ml-3">
                <a href="tel:0582951635" title="Appelez nous au 05 82 95 16 35">05 82 95 16 35</a>
              </span>
            </dd>
          </div>
        
        </dl>
      </div>
    </div>
    <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
      <div className="max-w-lg mx-auto lg:max-w-none">
      <form className="w-full max-w-lg mt-10 mx-auto" action="/success" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
      <div className="hidden">
        <label>Ne pas remplir <input name="bot-field" /></label>    
      </div>
        <input type="hidden" name="form-name" value="contact" />
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="name">
              Nom
            </label>
            <input className="appearance-none block w-full text-gray-700 border border-gray-200  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="nom" id="name" type="text" placeholder="Nom Prénom"/>          
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="company">
              Société
            </label>
            <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="societe" id="company" type="text" placeholder="Société"/>
          </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="telephone">
              Téléphone
            </label>
            <input className="appearance-none block w-full text-gray-700 border border-gray-200  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="telephone" id="telephone" type="text" placeholder="Téléphone"/>          
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">
              E-mail
            </label>
            <input className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="email" id="email" type="email" placeholder="E-mail"/>
          </div>
        </div>
        
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea rows="5" className="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="message" id="message" type="text" placeholder="Message"/>
          </div>
        </div>
        
        <div className="text-center">
          <button className="shadow bg-red-500 hover:bg-red-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit" aria-label="Envoyer">
              Envoyer
          </button>
        </div>
      </form>
      </div>
    </div>
  </div>
</div>
</div>



  
    </div>
    </Layout>
  );
}

export default ContactPage;
